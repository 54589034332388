$tab-link-height: 50px;
$tab-link-width-small: 170px;
$tab-link-width-large: 220px;
$tab-border-radius: 50px;
$tab-color-black: var(--black-100);
$tab-color-blue: var(--blue-100);
$tab-color-white: var(--white-100);
$transition-speed: 0.2s;

@mixin respond-to($breakpoint) {
    @if $breakpoint == "large" {
        @media screen and (max-width: 1440px) {
            @content;
        }
    } @else if $breakpoint == "medium" {
        @media screen and (max-width: 1100px) {
            @content;
        }
    } @else if $breakpoint == "small" {
        @media screen and (max-width: 768px) {
            @content;
        }
    }
}

@mixin hoverColor($color) {
    &:hover span,
    &:hover svg {
        color: $color;
    }
}

.tab-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left,
    &-right,
    .tab-container-background {
        display: flex;
        position: relative;
        align-items: stretch;
    }

    &-left {
        flex-direction: row;
        background-color: $tab-color-white;
        width: fit-content;
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, 0.08);
        border-radius: $tab-border-radius;
        .tab-container-background {
            background-color: var(--black-10);
            border-radius: $tab-border-radius;
        }

        a {
            text-decoration: none;
        }

        .tab-background {
            position: absolute;
            height: $tab-link-height;
            background-color: $tab-color-blue;
            transition: all $transition-speed;
            border-radius: $tab-border-radius;
            z-index: 998;
        }

        .tab-link,
        .tab-active {
            display: flex;
            justify-content: center;
            flex-direction: row-reverse;
            gap: 8px;
            align-items: center;
            padding: 8px 14px;
            height: $tab-link-height;
            width: $tab-link-width-small;
            border-radius: $tab-border-radius;
            color: $tab-color-black;
            font-size: clamp(1rem, 0.9643rem + 0.1786vw, 1.0625rem);
            transition: all $transition-speed;
            font-weight: 600;
            border: none;
            background-color: transparent;
            z-index: 999;

            span {
                color: $tab-color-black;
                white-space: nowrap;
                transition: all $transition-speed;
            }

            svg {
                color: var(--pink-100);
                transition: all $transition-speed;
            }

            .new-tag {
                border-radius: 12px;
                padding: 4px 7px;
                text-align: center;
                font-size: 0.75rem;
                text-transform: uppercase;
            }

            .active-with-reservations {
                background-color: var(--white-100);
                color: var(--pink-100);
            }

            .active-no-reservations {
                background-color: var(--white-100);
                color: var(--pink-100);
            }

            .default {
                background-color: var(--pink-100);
                color: var(--white-100);
            }
        }

        .tab-link-large {
            width: $tab-link-width-large;
        }

        .tab-active {
            span,
            svg {
                color: $tab-color-white;
            }
        }
    }

    .tab-background-livie {
        transform: translateX(170px); // Par défaut Livie est à droite
        width: $tab-link-width-large;
        background-color: #c65fd8;

        &.single-tab {
            transform: translateX(0px); // Si Livie est le seul onglet
        }
    }

    .tab-background-reservations {
        transform: translateX(0);
        width: $tab-link-width-small;
        background-color: $tab-color-blue;
    }
}

.MuiPaper-root {
    padding: 14px 12px 12px 12px !important;
    display: flex !important;
    gap: 50px !important;
    border: 1px solid var(--black-15) !important;
    .MuiList-root {
        display: flex !important;
        flex-direction: column !important;
        gap: 12px !important;
        width: 100% !important;
        #titre-filtre {
            font-size: 0.9375rem !important;
            font-weight: 600 !important;
        }
        .hr-texte {
            line-height: 1rem !important;
            position: relative !important;
            outline: 0 !important;
            border: 0 !important;
            color: var(--black-100) !important;
            text-align: center !important;
            height: 20px !important;
            opacity: 0.5 !important;
            margin: 0 !important;
            &:before {
                content: "" !important;
                background: linear-gradient(
                    to right,
                    transparent,
                    var(--black-50),
                    transparent
                ) !important;
                position: absolute !important;
                left: 0 !important;
                top: 50% !important;
                width: 100% !important;
                height: 1px !important;
            }
            &:after {
                content: attr(data-content) !important;
                position: relative !important;
                display: inline-block !important;
                color: var(--black-100) !important;
                font-size: 0.8125rem !important;
                font-family: var(--primary-font) !important;
                font-weight: bold !important;
                text-transform: uppercase !important;
                padding: 0 10px !important;
                line-height: 1.5rem !important;
                color: var(--black-100) !important;
                background-color: var(--white-100) !important;
            }
        }
        .date-menu-list {
            display: grid !important;
            grid-template-columns: repeat(2, 1fr) !important;
            flex-direction: column !important;
            gap: 8px !important;
            &::-webkit-scrollbar {
                display: block !important;
                width: 2.5px !important;
            }

            &::-webkit-scrollbar-track {
                background: var(--black-10) !important;
            }

            &::-webkit-scrollbar-thumb {
                background: var(--black-15) !important;
                &:active {
                    background-color: var(--black-intermediate) !important;
                }
            }
        }
        .MuiMenuItem-root {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            gap: 8px !important;
            padding: 8px !important;
            font-family: var(--primary-font) !important;
            border-radius: 8px !important;
            font-size: 0.8125rem !important;
            font-weight: 600 !important;
            transition: all 0.3s !important;
        }
        hr {
            height: 0 !important;
            width: 100% !important;
            border-radius: 8px !important;
            background-color: transparent !important;
            border: 1px solid var(--black-50) !important;
            margin: 5px auto !important;
        }
    }
    .filtreActions {
        width: 100% !important;
        display: flex !important;
        gap: 8px !important;
        align-items: center !important;
        #btn-reset {
            display: flex !important;
            align-items: center !important;
            justify-content: space-between !important;
            width: fit-content !important;
            gap: 8px !important;
            padding: 8px 12px !important;
            font-family: var(--primary-font) !important;
            border-radius: 8px !important;
            font-size: 0.8125rem !important;
            border: 1px solid var(--black-15) !important;
            font-weight: 600 !important;
            color: var(--black-100) !important;
            transition: all 0.3s !important;
            img {
                width: 12.5px !important;
                height: 12.5px !important;
                aspect-ratio: 1/1 !important;
            }
            &:hover {
                border: 1px solid var(--black-30) !important;
            }
        }
        .MuiMenuItem-root:nth-child(1) {
            border-radius: 8px !important;
            border: 1px solid var(--red-170-40) !important;
            padding: 8px 12px !important;
            font-family: var(--primary-font) !important;
            font-size: 0.8125rem !important;
            color: var(--red-170) !important;
            font-weight: 600 !important;
            width: 100% !important;
            &:hover {
                border: 1px solid var(--red-170) !important;
            }
        }
        .MuiMenuItem-root:nth-child(4) {
            background-color: var(--blue-100) !important;
            border-radius: 8px !important;
            border: 1px solid transparent !important;
            color: var(--white-100) !important;
            padding: 8px 12px !important;
            font-family: var(--primary-font) !important;
            font-size: 0.8125rem !important;
            font-weight: 600 !important;
            &:hover {
                background-color: var(--blue-110) !important;
            }
        }
        hr {
            height: 50% !important;
            width: 2px !important;
            border-radius: 8px !important;
            background-color: transparent !important;
            border: 1px solid var(--black-50) !important;
            margin: 0 auto !important;
        }
    }
}

#bouton-export {
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    padding: 8px 10px;
    border-radius: 12px;
    white-space: nowrap;
    transition: all 0.3s;
    background-color: var(--blue-20);
    border: 1px solid var(--blue-70);
    height: 42.5px;

    .bouton-text,
    .bouton-icon {
        transition: all 0.3s;
        color: var(--blue-100);
        font-size: 0.875rem;
        font-weight: 600;
    }

    .bouton-icon {
        width: 22.5px;
        height: 22.5px;
        object-fit: cover;
        aspect-ratio: 1/1;
    }

    &:hover {
        border: 1px solid $tab-color-blue;
        background-color: $tab-color-blue;

        .bouton-text,
        .bouton-icon {
            color: $tab-color-white;
        }
    }
}

#bouton-export.loading {
    &:hover {
        background-color: var(--blue-20);
        border: 1px solid var(--blue-70);
        cursor: wait;

        .bouton-text,
        .bouton-icon {
            color: var(--blue-100);
        }
    }
}

.exportWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.3s;
    color: var(--blue-100);
    font-size: 0.875rem;
    font-weight: 600;
    select {
        border-radius: 12px;
        white-space: nowrap;
        transition: all 0.3s;
        padding-inline: 8px;
        background-color: var(--blue-20);
        border: 1px solid var(--blue-70);
        transition: all 0.3s;
        color: var(--blue-100);
        font-size: 0.875rem;
        font-weight: 600;
        height: 42.5px;
        cursor: pointer;
        &:hover {
            border-color: var(--blue-100);
        }
        &:focus-visible {
            outline: none;
        }
    }
}

@include respond-to("large") {
    .tab-container {
        overflow-x: scroll;
        gap: 10px;
    }
}

@include respond-to("medium") {
    #bouton-export {
        width: 40px;
        height: 40px;
        overflow: initial !important;

        .bouton-text {
            display: none;
        }

        .bouton-icon {
            position: initial !important;
            bottom: initial !important;
        }
    }
}

@include respond-to("small") {
    .tab-container {
        flex-direction: column;
        max-width: 90%;
        margin: 0 auto;
        .tab-container-left {
            padding: 0;
        }
        .tab-container-right {
            width: 100%;
            flex-direction: column-reverse;
            hr {
                display: none;
            }
        }
    }
}

.test {
    color: var(--blue-100) !important;
    border: 1px solid var(--blue-70) !important;
    background-color: var(--white-100) !important;
    &:hover {
        background-color: var(--blue-20) !important;
        border-color: var(--blue-70) !important;
    }
}

.tab-active-livie-only {
    color: $tab-color-white;
    svg {
        fill: $tab-color-white;
    }
}
