.main-bg {
  z-index: -1;
  width: 100%;
  height: 100%;
  max-height: 100%;
  opacity: 0.1;
  object-fit: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
}

.main-app {
  width: 100%;
  height: 100vh;
  height: 100dvh;
  display: flex;
  position: relative;
  background-color: var(--blue-100);
}

.main-app-right-part {
  width: 100%;
  display: flex;
  gap: 30px;
  flex-direction: column;
  border-radius: 20px 0 0 20px;
  background-color: var(--black-5);
  min-height: 100vh;
  min-height: 100dvh;
}

.grid .main-app-right-part {
  width: 100%;
}

.main-navbar {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
  padding: 30px 30px 0px 30px;
  button {
    border: 1px solid var(--black-30);
    border-radius: 10px;
    width: 40px;
    height: 40px;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black-10);
    img {
      width: 20px;
      height: 20px;
      aspect-ratio: 1/1;
      transform: rotate(-180deg);
    }
  }
  hr {
    width: 100%;
    height: 2px solid var(--black-30);
  }
  .navbar-container {
    display: flex;
    gap: 15px;
    button {
      border: 1px solid var(--black-30);
      border-radius: 10px;
      width: 40px;
      height: 40px;
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--black-10);
      img {
        width: 20px;
        height: 20px;
        aspect-ratio: 1/1;
        transform: rotate(-180deg);
      }
    }
    .searchbar-container {
      width: 300px;
    }
  }
}

.clients tbody a {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.clients thead th:last-child,
.clients tbody td:last-child {
  padding-right: 30px;
  width: 50px;
}

.macro tbody td {
  padding: 10px 0;
}

.macro .topTable {
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.75fr 0.75fr;
  font-size: 0.8rem;
  padding: 0 0 10px 0;
  margin: 0;
}

.macro .topTable div {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  margin: 0;
}

.macro thead th {
  align-items: center;
  text-align: center;
}

.macro .list-container.non-selectable thead th:first-child {
  padding-left: 20px !important;
}

.macro thead th::before {
  left: 15% !important;
}

.macro thead th:nth-child(3),
.macro thead th:nth-child(4),
.macro thead th:nth-child(5) {
  background: var(--red-30);
}

.macro thead th:nth-child(6),
.macro thead th:nth-child(7),
.macro thead th:nth-child(8) {
  background: var(--yellow-30);
}

.macro thead th:nth-child(9),
.macro thead th:nth-child(10),
.macro thead th:nth-child(11) {
  background: var(--green-30);
}

.macro tbody a {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.macro tbody a p {
  margin: 0;
}

.macro tbody a .pourcent {
  font-size: 0.7rem;
  padding: 2px 10px;
  border-radius: 8px;
}

.margin {
  margin: 30px 0 0 0;
}

.gap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.new-button {
  position: absolute;
  right: 18px;
  top: 17px;
  font-size: 28px;
  font-weight: 200;
  color: var(--black-70);
  transition: all 0.5s;
  z-index: 2;
}

.new-button:hover {
  color: var(--white-100);
}

.new-button:before {
  content: "";
  position: absolute;
  top: 9px;
  right: -5px;
  width: 26px;
  height: 26px;
  border: 1px solid var(--black-15);
  border-radius: 9px;
  z-index: -1;
  transition: all 0.5s;
}

.new-button:hover:before {
  background: var(--blue-100);
  border: 1px solid var(--blue-100);
}

.searchbar-item input {
  height: 40px;
  width: 100%;
  border: 1px solid var(--black-15);
  border-radius: 10px;
  background: var(--black-5);
  padding: 0 20px 0 20px;
  font-weight: 500;
  font-size: 0.9em;
}

.searchbar-item {
  position: relative;
}

.searchbar-item:before {
  content: "";
  position: absolute;
  left: 12px;
  top: 12px;
  width: 18px;
  height: 22px;
  background: url("../../assets/icons/search.svg") no-repeat;
  background-size: contain;
  z-index: 999;
}

.selectbar-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .datepickers-container {
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--black-50);
    .MuiFormControl-root {
      .MuiFormLabel-root {
        font-family: var(--primary-font);
        font-size: .9375rem;
        font-weight: 500;
        left: 2px;
      }
      input {
        height: 40px;
        width: 100%;
        border-radius: 12px;
        padding: 0 0 0 15px;
        font-weight: 600;
        font-size: 0.8125rem;
        font-family: var(--primary-font);
        &:focus {
          outline: none !important;
          border: none !important;
        }
      }
    }
    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
      aspect-ratio: 1/1;
      color: var(--blue-80);
      margin: 0 0 0 auto;
    }
  }
}

.css-1d8n9bt {
  height: 40px !important;
  align-content: center !important;
}

.searchbar-item input.select__input {
  width: 250px !important;
  padding: 0px !important;
  border-radius: 0 !important;
}

.selectbar-item .MuiTextField-root {
  width: 160px;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: none;
  }
}

.Mui-focused {
  outline: none !important;
  &:focus-visible {
    outline: none !important;
  }
}

.selectbar-item .compared-to {
  padding-inline-end: 10px;
  color: var(--black-70);
  white-space: nowrap;
  font-size: .9375rem;
}

.select__value-container {
  padding: 0 0 0 8px !important;
}

.flex-button-right {
  margin-left: 10px;
}

.user-item {
  height: 40px;
  background: var(--blue-100);
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  font-weight: 700;
  padding: 0 50px 0 50px;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  width: fit-content;
  transition: all 0.3s;
  color: var(--white-100);
}

.user-item:hover {
  background: var(--blue-100);
  color: var(--white-100);
}

.user-item:hover:before {
  background: url("../../assets/icons/account-white.svg") no-repeat;
}

.user-item:hover:after {
  opacity: 0.5;
}

.user-item:before {
  content: "";
  position: absolute;
  left: 12px;
  top: 9px;
  width: 17px;
  height: 22px;
  background: url("../../assets/icons/account.svg") no-repeat;
  background-size: contain;
  transition: all 0.3s;
}

.user-item:after {
  content: "";
  position: absolute;
  right: 12px;
  top: 16px;
  width: 15px;
  height: 22px;
  background: url("../../assets/icons/arrow-toggle-white.svg") no-repeat;
  background-size: contain;
  transition: all 0.3s;
}

.user-options {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user-options a {
  text-decoration: none;
}

.user-options-item {
  height: 40px;
  border: 1px solid var(--blue-60);
  border-radius: 10px;
  background: var(--white-100);
  padding-left: 40px;
  padding-right: 20px;
  color: var(--blue-100);
  font-size: 0.9em;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  justify-content: flex-end;
  transition: all 0.3s;
}

.user-options-item:before {
  content: "";
  position: absolute;
  left: 12px;
  top: 9px;
  width: 20px;
  height: 22px;
  background: url("../../assets/icons/logout--red.svg") no-repeat;
  background-size: contain;
}

.user-options-item.launch {
  border: 1px solid var(--green-100);
  color: var(--green-100);
}

.user-options-item.launch:hover {
  color: white;
  background: var(--green-100);
}

.user-options-item {
  border: 1px solid var(--red-100);
  color: var(--red-100);
}

.user-options-item:hover,
.user-options-item:hover {
  background: var(--red-100);
  color: var(--white-100);
}

.user-options-item:hover:before {
  background: url("../../assets/icons/logout--white.svg") no-repeat;
  background-size: contain;
}

.user-options-item.launch:hover:before {
  background: url("../../assets/icons/launch--white.svg") no-repeat;
  background-size: contain;
}

.user-options-item.link:before {
  background: url("../../assets/icons/link--blue.svg") no-repeat;
  background-size: contain;
  transition: all 0.3s;
  height: 18px;
  top: 10px;
}

.user-options-item.launch:before {
  background: url("../../assets/icons/launch--green.svg") no-repeat;
  background-size: contain;
  transition: all 0.3s;
  height: 20px;
  top: 9px;
}

.user-options-item.delete:before {
  background: url("../../assets/icons/delete--red.svg") no-repeat;
  background-size: contain;
  transition: all 0.3s;
  top: 11px;
  left: 13px;
  width: 16px;
}

.user-options-item.delete:hover:before {
  background: url("../../assets/icons/delete--white.svg") no-repeat;
  background-size: contain;
}

.user-options-item.archive:before {
  background: url("../../assets/icons/archive--blue.svg") no-repeat;
  background-size: contain;
  transition: all 0.3s;
  top: 12px;
  left: 12px;
  width: 19px;
}

/* === Media Query === */

@media screen and (max-width: 1280px) {
  .main-app-right-part {
    border-radius: 0px;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 1024px) {
  .main-navbar {
    border-bottom: 0px;
    padding-bottom: 0px;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 30px;
    .navbar-container {
      width: 100%;
      justify-content: space-between;
      .user-item {
        padding: 0 50px 0 15px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main-navbar, .main-app-right-part {
    gap: 15px;
  }
  .main-navbar {
    padding: 15px;
  }
}

@media screen and (max-width: 425px) {
  .main-navbar .navbar-container .searchbar-container {
    width: 100%;
  }
}

.MuiButtonBase-root:hover {
  background-color: transparent !important;
}