.custom-tag {
    &.active,
    &.closed,
    &.credit,
    &.true,
    &.dedge-smart {
        background: var(--green-30);
        color: var(--green-170);
    }

    &.progress {
        background: var(--yellow-30);
        color: var(--yellow-170);
    }

    &.pending {
        background: var(--yellow-30);
        color: var(--yellow-170);
    }

    &.production,
    &.uniforme-prestige-school,
    &.reservit {
        background: var(--orange-30);
        color: var(--orange-120);
    }

    &.inactive,
    &.difficulty,
    &.debit,
    &.false {
        background: var(--red-30);
        color: var(--red-170);
    }

    &.archived,
    &.draft {
        background: var(--black-15);
        color: var(--black-70);
    }

    &.fabrics,
    &.dedge-attraction {
        background: var(--purple-30);
        color: var(--purple-120);
    }
}

.MuiButtonBase-root.custom-tag {
    padding: 10px;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.625rem !important;
}

.custom-tag-container {
    width: 100%;
    padding: 0.5rem 0 0.5rem 10px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    transition: all 0.3s;
    &:hover {
        background: var(--black-10);
    }
    &.selected {
        padding: 0.125rem 0;
        &:hover {
            background: var(--white-100);
        }
        .invoice-option-select {
            margin-right: 0;
            padding: 0.125rem 0.5625rem;
        }
    }
}

#sauvegarder_bouton {
    &:hover {
        border: 1px solid var(--black-15);
    }
}