/* === Livie Style === */

.tab-livie {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;

  .header-title {
    p {
      color: var(--black-100);
      font-size: 0.9rem;
      font-style: italic;
      padding-left: 2.25rem;
    }
  }

  .section-livie-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    .article-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      height: fit-content;
      gap: 1.25rem;

      #nb_token {
        grid-template-columns: 1fr;

        #nb_tokens_article {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          gap: 1rem;

          .c-card-data_percentage {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;

            .c-progress-bar {
              position: relative;
              height: 30px;

              progress {
                -webkit-appearance: none;
                appearance: none;
                width: 100%;
                height: 100%;
                border-radius: 8px;
                background-color: var(--black-10);

                &::-webkit-progress-bar {
                  background-color: var(--blue-70);
                  border-radius: 8px;
                }

                &::-webkit-progress-value {
                  background-color: var(--blue-100);
                  border-radius: 8px;
                }

                &::-moz-progress-bar {
                  background-color: var(--blue-70);
                  border-radius: 8px;
                }
              }

              span {
                position: absolute;
                left: 8px;
                top: 50%;
                transform: translateY(-50%);
                line-height: 30px;
                color: var(--white-100);
                font-weight: bold;
                font-size: 0.9rem;
              }
            }

            label {
              display: block;
              margin-top: 8px;
              border-left: 2px solid var(--blue-110);
              padding-left: 0.625rem;
              font-size: 1.25rem;
              font-weight: 900;
              color: var(--blue-110);
            }
          }
        }
      }
    }
  }
}

.table-wrapper-livie {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.view-conversation {
  width: fit-content;
  display: flex;
  float: right;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: none;
  background: rgba(59, 97, 225, 0.9);
  backdrop-filter: blur(7.5px);
  color: var(--white-100);
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.12px;
  transition: all 0.3s;
  white-space: nowrap;

  &:hover {
    background-color: var(--blue-110);
  }
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 999 !important;

  .ReactModal__Content {
    border-radius: 18px !important;
    height: fit-content;
    width: fit-content;
    padding: 0px !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    overflow: visible !important;
  }
}

.modal-livie-wrapper {
  width: 500px;
  border-radius: 18px;
  overflow: hidden;

  .modal-livie-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 12px;
    align-items: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.04) 100%), var(--white-100);
    box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.15);

    .modal-livie-header-icon {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: -0.15px;

        span {
          transition: opacity 0.2s ease-in-out;
        }
      }
    }

    button {
      border: none;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      padding: 3px;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      transition: all 0.3s ease-in-out;
      border: 1px solid var(--black-15);

      &:hover {
        border: 1px solid var(--blue-100);
        background-color: var(--blue-100);

        img {
          filter: invert(1);
        }
      }
    }
  }

  .conversation-navigation {
    button {
      width: 40px;
      height: 40px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50% !important;
      position: absolute;
      background-color: var(--white-100);
      box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.15);
      transition: all 0.3s;
      cursor: pointer;

      img {
        width: 25px;
        height: 25px;
      }
    }

    #conversation-precedente,
    #conversation-suivante {
      top: 50%;
      transform: translateY(-50%);
    }

    #conversation-precedente {
      left: -80px;

      img {
        transform: rotate((-180deg));
      }
    }

    #conversation-suivante {
      right: -80px;
    }
  }
}

.modal-livie-content {
  display: flex;
  padding: 20px 12px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(480px - 49px);
  transition: all 0.2s ease-in-out;
  word-break: break-word;

  &::-webkit-scrollbar {
    display: block;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--black-10);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--black-15);

    &:active {
      background-color: var(--black-intermediate);
    }
  }

  .modal-livie-text-ai {
    display: flex;
    padding-right: 40px;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;

    p {
      padding: 10px 12px;
      border-radius: 15px 15px 15px 5px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%), var(--white-100);
    }
  }

  .modal-livie-text-users {
    margin-left: 50px;
    display: flex;
    padding: 9px 12px 10px 12px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 15px 15px 5px 15px;
    background: var(--blue-100);
    width: fit-content;
    max-width: 70%;
    margin: 0 0 0 auto;

    p {
      color: var(--white-100);
    }
  }
}

.conversation-cell {
  width: 30vw;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.modal-content-changing {
  opacity: 0;
}

.tab-livie-prospect {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 20px;
  padding: 60px 110px 40px 110px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: linear-gradient(197deg, rgba(59, 97, 225, 0.1) 0%, rgba(59, 97, 225, 0) 88.31%);
  .tab-livie-content {
    width: 100%;
    max-width: 80rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    gap: 50px;
    .cta-wrapper-texte {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 30px;
      p {
        width: 80%;
      }
      .btn-typeform {
        margin-top: 13px;
        display: flex;
        padding: 14px 18px 13px 18px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        border-radius: 12px;
        background: var(--blue-100);
        color: var(--white-100);
        border: none;
        font-weight: 500;
        text-transform: uppercase;
        transition: all 0.3s;
        img {
          width: 16px;
          height: 16px;
          aspect-ratio: 1/1;
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }
    video {
      max-width: 300px;
      border-radius: 1rem;
      border: 1px solid var(--black-15);
      box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
    }
  }
}

@media screen and (max-width: 1280px) {
  .tab-livie {
    .article-wrapper {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}

@media screen and (max-width: 869px) {
  .tab-livie {
    .article-wrapper {
      grid-template-columns: 1fr !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .stats-livie,
  .section-livie-wrapper {
    margin-bottom: 30px !important;
  }

  .tab-livie {
    gap: 0px !important;
  }

  .modal-livie-wrapper {
    width: 310px !important;
  }

  .conversation-navigation {
    #conversation-precedente,
    #conversation-suivante {
      bottom: -75px !important;
      top: initial !important;
    }

    #conversation-precedente {
      left: 50px !important;
    }

    #conversation-suivante {
      right: 50px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .conversation-navigation.admin-hidden {
    display: none;
  }

  .conversation-navigation {
    #conversation-precedente {
      left: 75px !important;
    }

    #conversation-suivante {
      right: 75px !important;
    }
  }

  .tab-livie {
    padding-bottom: 79px;
  }
}

.livie-row {
  display: grid;
  grid-template-columns: 1fr;
  background-color: var(--black-10);
  color: var(--black-60);
  width: 100%;
  gap: 1.25rem;
  border-radius: 1rem;

  p {
    margin: 0;
    padding: 8px 12px;
  }
}
