@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-Regular.woff2") format("woff2"),
    url("../fonts/Manrope-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-Bold.woff2") format("woff2"),
    url("../fonts/Manrope-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-Light.woff2") format("woff2"),
    url("../fonts/Manrope-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-Medium.woff2") format("woff2"),
    url("../fonts/Manrope-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
